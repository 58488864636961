<template>
    <div class="container">
        <h3 class="title">{{ title }}</h3>
        <p class="description">{{ description }}</p>
        <div class="links-display">
            <div class="social-link-container">
                <a class="social-link" href="mailto:jack@icefairy.xyz" target="_blank" rel="noopener noreferrer">
                    <fa-icon icon="fa-regular fa-envelope" />
                </a>
                <a class="social-link" href="https://github.com/signalmoroll" target="_blank" rel="noopener noreferrer">
                    <fa-icon icon="fa-brands fa-2xl fa-github" />
                </a>
                <a class="social-link" href="https://x.com/faunapilled" target="_blank" rel="noopener noreferrer">
                    <fa-icon icon="fa-brands fa-lg fa-x-twitter" />
                </a>
                <a class="social-link" href="https://www.geocaching.com/p/?guid=c3a60188-0924-4982-8f05-b92be144a4dd" target="_blank" rel="noopener noreferrer">
                    <fa-icon icon="fa-brands fa-geocaching" transform="flip-v shrink-10.4 down-2.5 left-6" />
                </a>
            </div>
        </div>
        <h3 class="subtitle">My Projects</h3>
        <p class="description">{{ subtitle1 }}</p>
        <div class="link-container">
            <LinkComponent image="/1Password_icon.png" title="salt-sdb-op - Secrets management for minions using 1Password" url="about:blank" />
            <LinkComponent image="/fuck-you-broadcom.png" title="mithril - IT Automation in Rust" url="about:blank" />
        </div>
        <h3 class="subtitle">Get to Know Me</h3>
        <div class="link-container">
            <LinkComponent image="/nvim.svg" title="My neovim Configuration" url="https://vuejs.org/" />
            <LinkComponent image="/IMG_6562.JPG" title="I fw this song heavy" url="https://www.youtube.com/watch?v=PmDSomhzk4U" />
        </div>
        <h3 class="subtitle">Learn More</h3>
        <div class="link-container">
            <LinkComponent image="/favicon.ico" title="built with VueJS.org Framework V3" url="https://vuejs.org/" />
        </div>
    </div>
</template>

<script>
import LinkComponent from './LinkComponent.vue';

export default {
    name: 'SocialMediaLinks',
    props: {
        title: String,
        description: String,
        subtitle1: String
    },
    components: {
        LinkComponent
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    color: var(--title-color);
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
.container {
    display: flex;
    flex-direction: column;
}

.social-link-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.25rem;
    width: 100%;
}

.description {
    color: var(--subtitle-color);
}

.social-link {
    font-size: 2em;
}

.social-link:hover {
    transform: scale(1.1);
}

.link-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
</style>
